import React, { useContext } from "react";
import { Layout } from "@layout";
import { Grid, Flex, Col, Alert, Title, Text, Button } from "@components";
import { TableProspectos } from "@admin/Prospectos";
import AdminContext from "@context";
import { navigate } from "gatsby";

function Prospectos() {
  const {
    auth: { username },
  } = useContext(AdminContext);

  return (
    <Layout title="Lista de clientes">
      <Grid className="pv:2">
        <Flex className="mb:1" justify="between">
          <Col autofit>
            <Title className="mb:1">Administración de clientes</Title>
          </Col>

          <Button
            square
            primary
            onClick={() => navigate("/admin/prospectos/agregar")}
          >
            Nuevo prospecto
          </Button>
        </Flex>

        <Flex className="mb:1">
          <Alert type="info" align="center" className="mb:1 mr:1">
            <Text fs={12} fw={300}>
              Hola {username}! te presentamos el nuevo diseño de prospectación
              con nuevas caracteristicas...
            </Text>
          </Alert>
        </Flex>

        <Text fs={16} fw={400} className="mb:05">
          Lista de Prospectos
        </Text>

        <TableProspectos />
      </Grid>
    </Layout>
  );
}

export default Prospectos;
